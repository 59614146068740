<template>
  <div class="chat-message" :class="[{ incoming: isIncoming }, { 'own-message': isMyMsg }]">
    <div class="chat-message__avatar">
      <Avatar :name="author.fullName" :image="author.avatar"/>
    </div>
    <div class="chat-message__group">
      <template v-for="messageItem in message" :key="messageItem.id">
        <div class="chat-message__wrapper">
          <div class="chat-message__text">
            <div class="chat-message__author" v-if="chatType === 'COMMUNITY'">
              {{ author.fullName }}
            </div>
            <div class="chat-message__text-content" v-html="messageItem.body"></div>
            <div class="chat-message__time">{{ messageItem.time}}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>


// TODO: Вернуть для выбора сообщений, когда логика будет готова.
// :class="{ selected: selectedMsg.includes(messageItem.id) }" @click="selectMsg(messageItem.id)"

import Avatar from '@component/Avatar';

export default {
  name: 'ChatMessageList',
  components: {
    Avatar
  },
  props: {
    isIncoming: {
      type: Boolean,
      default: false
    },
    message: {
      type: Array,
      required: false
    },
    author: {
      type: Object,
      default: () => {return {}; }
    },
    chatType: {
      type: String
    }
  },
  data() {
    return {
      selectedMsg: []
    };
  },
  computed: {
    isMyMsg() {
      let targetUser = USER.Profile.getProfileObj();
      let fullName = `${targetUser.firstName} ${targetUser.lastName}`;
      return fullName === this.author.fullName
    }
  },
  methods: {
    selectMsg(id) {
      if (this.selectedMsg.includes(id)) {
        let index = this.selectedMsg.findIndex(innerId => innerId === id);
        if (index > -1) {
          this.selectedMsg.splice(index, 1);
        }
      } else {
        this.selectedMsg.push(id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.chat-message {
  display: flex;
  align-items: flex-end;
  max-width: 50%;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .chat-message__avatar {
    flex: 0 0 2rem;
    @include icon-size(2);
    margin-top: 8px;
    margin-right: 24px;
  }

  .chat-message__group {
    display: flex;
    flex-direction: column;
  }

  .chat-message__wrapper {
    flex: 1 1 100%;
    display: flex;
    &:not(:last-of-type) {
      .chat-message__text {
        margin-bottom: 8px;
        border-radius: 12px;
      }
    }
    &.selected {
      .chat-message__text {
        background-color: var(--color-blue-03);
        & > .chat-message__time {
          color: var(--color-gray-06);
        }
      }
    }
  }
  
  .chat-message__author {
    font-size: 0.75rem;
    margin-bottom: 4px;
    flex: 1 1 100%;
  }

  .chat-message__text {
    border-radius: 12px 12px 12px 0;
    padding: 12px 24px;
    background-color: var(--chat-message-foe-background);
    display: flex;
    flex-wrap: wrap;
    min-width: 160px;
    white-space: pre-wrap;
  }

  .chat-message__text-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chat-message__text-content ::v-deep(a) {
    color: var(--color-secondary);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  
  // TODO: Remove it later
  .chat-message__text-content::v-deep(.cm-tag) {
      display: flex;
      padding: 4px 8px;
      border-radius: 8px;
      border: 1px solid;
      font-size: 0.875rem;
      align-items: center;
      border-color: var(--color-gray-03);
      color: var(--color-gray-06);
      cursor: pointer;
      .cm-tag--border-color {
        &[class*="pdf"] {
          border-color: #f68989;
          color: #e81d1d;
        }
        &[class*="board"] {
          border-color: #d4f185; // color-lime-03
          color: #729611; // color-lime-05
        }
        &[class*="doc"] {
          border-color: #97a1ec; // color-blue-03
          color: #2133da; // color-blue-05
        }
      }
    }
  .chat-message__text-content::v-deep(.cm-tag__icon) {
      display: inline-flex;
      margin-right: 4px;
      svg,
      img {
        @include icon-size(1.25);
      }
      svg {
        path {
          // fill: #C4C4C4;
        }
      }
      .cm-tag--board & {
        path {
          fill: #9ccb18;
        }
      }
    }
  .chat-message__text-content::v-deep(.cm-tag__close) {
      @include icon-size(1.25);
      margin-left: 8px;
      cursor: pointer;
      path {
        fill: var(--color-gray-04);
      }
    }
  

  .chat-message__time {
    color: var(--color-gray-04);
    font-size: 0.75rem;
    margin-left: 16px;
    float: right;
    min-width: 54px;
  }

  &.incoming {
    .chat-message__text {
      background-color: var(--chat-message-foe-background);
    }
  }

  &.own-message {
    .chat-message__text {
      background-color: var(--chat-message-own-background);
    }
  }

}

</style>
