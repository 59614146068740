import {
  RSocketClient,
  BufferEncoders,
  encodeCompositeMetadata,
  MESSAGE_RSOCKET_COMPOSITE_METADATA,
  MESSAGE_RSOCKET_ROUTING,
  encodeRoute,
} from 'rsocket-core';
import RSocketWebSocketClient from 'rsocket-websocket-client';
import { ref } from 'vue';
import { communityGetSelected } from '@store/community.store';
import { contactAdd, participantObj } from '@store/communityContact.store';

console.log(
  `wss://${process.env.VUE_APP_URL_RSOCKET}:${process.env.VUE_APP_URL_RSOCKET_PORT}`
);
/**
 * @typedef ChatMessageObjTypedef
 * @property  {String} id
 * @property  {String} body
 * @property  {String} userFrom - id юзера от кого получили сообщение
 * @property  {String} userTo
 * @property  {String} roomName - id комнаты чата
 * @property  {String} dateMessage
 */

const chatMessagesNew = ref({
  /*
  'chatId': {
    messageKey: {
      'messageId1': true,
      'messageId2': true,
    },
    messageList: [],
  }
  */
});
export const chatRooms = ref([]);

/**
 *
 * @param {String} chatId
 * @param {ChatMessageObjTypedef} messageObj
 */
function messageAdd(chatId, messageObj) {
  if (!chatMessagesNew.value[chatId]) {
    chatMessagesNew.value[chatId] = {
      messageKey: {},
      /** @type {ChatMessageObjTypedef[]} **/
      messageList: [],
    };
  }

  if (!chatMessagesNew.value[chatId].messageKey[messageObj.id]) {
    chatMessagesNew.value[chatId].messageKey[messageObj.id] = true;
    chatMessagesNew.value[chatId].messageList.push(messageObj);
  }
}

export const getChatById = (chatId) => {
  if (!chatMessagesNew.value[chatId]) {
    chatMessagesNew.value[chatId] = {
      messageKey: {},
      /** @type {ChatMessageObjTypedef[]} **/
      messageList: [],
    };
  }

  return chatMessagesNew.value[chatId].messageList;
};

// getters
export function getChatRooms() {
  return chatRooms;
}

export async function fetchChatRooms() {
  try {
    const chatRoomArr = await RequestManager.Chat.getChatRooms();
    const currentUserId = USER.Auth.getUserId();

    for (let i = 0; i < chatRoomArr.length; i++) {
      let chatRoomObj = chatRoomArr[i];
      let userId = null;
      if (chatRoomObj.usersRef.length) {
        if (chatRoomObj.usersRef[0].id === currentUserId) {
          userId = chatRoomObj.usersRef[1].id;
        } else {
          userId = chatRoomObj.usersRef[0].id;
        }

        if (!participantObj.value[userId]) {
          contactAdd({ id: userId });
        }
      }
    }
    chatRooms.value = chatRoomArr;
  } catch (err) {
    console.error(err);
  }
}

const setMessageAuthor = (userId) => {
  let targetUser = null;
  let unknown = 'Anonymous User';
  if (userId === USER.Auth.getUserId()) {
    targetUser = USER.Profile.getProfileObj();
  } else {
    targetUser = communityGetSelected().value.participants.find(
      (user) => user.id === userId
    );
  }

  return {
    avatar:
      targetUser && targetUser.profilePhoto ? targetUser.profilePhoto : null,
    fullName:
      targetUser && targetUser.firstName && targetUser.lastName
        ? `${targetUser.firstName} ${targetUser.lastName}`
        : unknown,
  };
};

export function fetchChatHistory(chatId) {
  RequestManager.Chat.getChatHistory({
    chatId: chatId,
    page: 1,
    pageSize: 100,
  }).then((res) => {
    for (let i = 0; i < res.length; i++) {
      messageAdd(res[i].roomName, res[i]);
    }
  });
}

export const getPrepareChatDataByChatId = (chatId) => {
  const chatMessageList = getChatById(chatId);
  return prepareChatData(chatMessageList);
};

export const prepareChatData = (chatMessagesList) => {
  let output = {};
  let lastMessageObjItem = null;
  let lastDate = null;
  if (chatMessagesList.length) {
    chatMessagesList.forEach((chat) => {
      if (!output[chat.roomName]) {
        let obj = {};
        obj.roomName = chat.roomName;
        obj.messageDate = [];
        obj.messageObj = {};
        output[chat.roomName] = obj;
      }

      let date = DateTime(chat.dateMessage).format('YYYY-MM-DD');
      if (!output[chat.roomName].messageObj[date]) {
        output[chat.roomName].messageObj[date] = [];
        output[chat.roomName].messageDate.push(date);
      }

      const author = setMessageAuthor(chat.userFrom);

      let messageObjItem = {
        author: author,
        message: [],
      };

      if (
        lastMessageObjItem &&
        lastMessageObjItem.author.fullName === author.fullName &&
        date === lastDate
      ) {
        messageObjItem = lastMessageObjItem;
      } else {
        lastMessageObjItem = messageObjItem;
        lastDate = date;
        // НЕ УДАЛЯТЬ output
        output[chat.roomName].messageObj[date].push(messageObjItem);
      }

      messageObjItem.message.push({
        id: chat.id,
        body: chat.body,
        time: DateTime(chat.dateMessage).format('hh:mm A'),
      });
    });
    return output;
  }
};

// исправление переходов chat email chat (после отправка сообщений дублируется в два сокета)
let rsocketConnectRun = false;

export async function connect(reconect = false) {
  if (rsocketConnectRun && !reconect) {
    return;
  }
  rsocketConnectRun = true;

  const maxRSocketRequestN = 2147483647;
  const keepAlive = 60000;
  const lifetime = 180000;
  const dataMimeType = 'application/octet-stream';
  const metadataMimeType = MESSAGE_RSOCKET_COMPOSITE_METADATA.string;
  const route = 'getChatMessages';

  const metadatas = encodeCompositeMetadata(
    new Map([[MESSAGE_RSOCKET_ROUTING, encodeRoute(route)]])
  );

  const tokenBuffer = Buffer.from(USER.Auth.getToken());

  let socketInstance = null;

  const client = new RSocketClient({
    setup: {
      dataMimeType,
      keepAlive,
      lifetime,
      metadataMimeType,
      payload: {
        data: tokenBuffer,
        metadata: metadatas,
      },
    },
    transport: new RSocketWebSocketClient(
      {
        debug: true,
        url: `wss://${process.env.VUE_APP_URL_RSOCKET}:${process.env.VUE_APP_URL_RSOCKET_PORT}`, // 'wss://ws.dev.chatmail.rtelekom.sparklingtide.dev:7091',
        wsCreator: (url) => new WebSocket(url),
      },
      BufferEncoders
    ),
  });

  const requestStreamRun = (socket) => {
    // console.log('Socket: ', socket);
    // observe rsocket status
    // socket.connectionStatus().subscribe(event => console.log('connectionStatus: ', event));
    socket
      .requestStream({
        data: tokenBuffer,
        metadata: metadatas,
      })
      .subscribe({
        // eslint-disable-next-line no-console
        onComplete: (data) => console.log('Request-stream completed', data),
        onError: (error) => {
          console.error(`Request-stream error:${error.message}`);
          setTimeout(() => {
            console.log('Request-stream reconnect...');
            // requestStreamRun(socket);
            client.close();
            connect(true);
          }, 5000);
        },
        // eslint-disable-next-line no-console
        onNext: (value) => {
          /** @type {ChatMessageObjTypedef} */
          const messageFromSocket = JSON.parse(value.data.toString());
          messageAdd(messageFromSocket.roomName, messageFromSocket);
        },
        onSubscribe: (sub) => sub.request(maxRSocketRequestN),
      });
  };

  // Open the connection
  client.connect().then(requestStreamRun, (error) => {
    // handle connection error
    // eslint-disable-next-line no-console
    console.error('error:', error);
  });
}
