<script>

//         @keypress.enter.prevent.stop="newLine"

import FveTextarea from "@fve/Element/Text/FveTextarea";

export default {
  name: 'FveTextareaResize',
  mixins: [
    FveTextarea
  ],
  methods: {
    inputValueUpdate($event) {
      this.fieldValueUpdate({input: $event.target.value});
      const el = this.$refs.input;
      el.style.height = el.scrollHeight + "px";
    },
  }
};
</script>

<style lang="scss" scoped>

@import "~@fve/style/textarea.scss";

</style>
