<template>
  <Dropzone class="chat-input" @onDrop="selectFileList" v-slot="{ hide, show }">
    <!--    <EmailDelaySending/>-->
    <DropzoneDraggable
      @drag-start="show"
      @drag-end="hide"
      :items="files"
      @update:items="onUpdateItems"
    />
    <!-- :items="files"
      @update:items="onElementDrop" -->
    <div class="chat-input__textarea">
      <!--      <div-->
      <!--          ref="chatInput"-->
      <!--          @input="onInput"-->
      <!--          contenteditable="true"-->
      <!--          role="textbox"-->
      <!--          style="outline: none"-->
      <!--      ></div>-->

      <FveTextareaResize
        ref="chatTextarea"
        v-model="message"
        :field="{
          name: 'message',
          model: true,
        }"
      />
    </div>
    <div class="chat-input__action">
      <input
        :id="`input-file-${_.uid}`"
        type="file"
        @change="(event) => selectFileList(event.target.files)"
        ref="inputFile"
        hidden
      />
      <div class="chat-input__attach" @click="$refs.inputFile.click()">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 5a1 1 0 10-2 0v5a1 1 0 01-1 1H5a1 1 0 100 2h5a1 1 0 011 1v5a1 1 0 102 0v-5a1 1 0 011-1h5a1 1 0 100-2h-5a1 1 0 01-1-1V5z"/></svg>
      </div>
      <div class="chat-input__send" @click="sendMsg">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.75 11.726h-15M13.7 5.701l6.05 6.024-6.05 6.025" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </div>
    </div>
  </Dropzone>
</template>

<script>
import DropzoneDraggable from '@component/DropzoneDraggable';
import FveTextareaResize from '@fve/Element/Text/FveTextareaResize';
// import EmailDelaySending from "@component/Email/EmailDelaySending";
import FveMixinForm from '@fve/Mixin/FveMixinForm';
import { communityGetSelected } from '@store/community.store';
import { contactGetSelected } from '@store/communityContact.store';
import { fileStorageGetPreview } from '@store/fileStorage.store';

import Dropzone from '@component/Dropzone';

// SOME DUMMY THINGS

const IMG_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.533 20h6.933C18.18 20 20 18.098 20 15.267V8.733C20 5.903 18.178 4 15.467 4H8.533C5.822 4 4 5.902 4 8.733v6.534C4 18.097 5.822 20 8.533 20zm.666-8.8c-1.103 0-1.999-.897-1.999-2s.896-2 2-2c1.101 0 1.998.897 1.998 2s-.897 2-1.999 2zm9.058 3.147c.267.687.128 1.513-.158 2.193-.34.809-.99 1.422-1.808 1.69a3.62 3.62 0 01-1.126.17H8.423c-.671 0-1.265-.161-1.751-.46-.305-.189-.36-.623-.133-.905.378-.47.751-.942 1.128-1.419.717-.911 1.2-1.175 1.738-.944.218.096.437.24.662.392.6.408 1.435.968 2.534.36.751-.421 1.188-1.144 1.567-1.773l.007-.01.08-.132c.128-.212.254-.42.396-.612.179-.24.84-.992 1.698-.457.546.337 1.006.793 1.497 1.28.188.187.321.4.41.627z"/></svg>`;
const DOC_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#505FE1"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#505FE1"/><path d="M7 14.058h1.426c.33 0 .623.059.878.177.256.118.454.286.595.503.14.217.21.47.21.762 0 .291-.07.545-.21.762-.141.217-.34.385-.595.503a2.073 2.073 0 01-.878.177H7v-2.884zm1.391 2.233c.259 0 .465-.07.62-.21.158-.14.237-.334.237-.581 0-.247-.079-.44-.237-.581-.155-.14-.361-.21-.62-.21h-.538v1.582h.538zM12.079 17c-.32 0-.606-.065-.862-.194a1.515 1.515 0 01-.599-.535 1.416 1.416 0 01-.215-.771c0-.286.072-.543.215-.77.147-.229.346-.407.6-.536.255-.13.542-.194.86-.194.32 0 .605.065.858.194.255.129.455.307.598.535.147.228.22.485.22.771 0 .286-.073.543-.22.77a1.478 1.478 0 01-.598.536c-.253.13-.539.194-.857.194zm0-.676c.152 0 .29-.034.413-.103a.761.761 0 00.293-.288.861.861 0 00.107-.433.839.839 0 00-.107-.429.754.754 0 00-.293-.292.837.837 0 00-.414-.103.837.837 0 00-.413.103.754.754 0 00-.293.292.838.838 0 00-.107.429.86.86 0 00.107.433c.072.123.17.22.293.288a.837.837 0 00.414.103zM15.704 17c-.316 0-.6-.063-.853-.19a1.491 1.491 0 01-.59-.535 1.424 1.424 0 01-.215-.775 1.424 1.424 0 01.805-1.306c.252-.13.537-.194.853-.194.275 0 .524.047.745.14.22.093.405.228.551.404l-.543.47a.907.907 0 00-.71-.338.922.922 0 00-.44.103.746.746 0 00-.297.292.866.866 0 00-.103.429.89.89 0 00.103.433c.072.123.171.22.297.288.13.069.276.103.44.103a.907.907 0 00.71-.338l.543.47c-.146.176-.33.31-.551.404-.221.093-.47.14-.745.14z" fill="#fff"/></svg>`;
const XLS_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z"/></svg>`;
const VIDEO_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.586 6.586C4 7.172 4 8.114 4 10v4c0 1.886 0 2.828.586 3.414C5.172 18 6.114 18 8 18h8c1.886 0 2.828 0 3.414-.586C20 16.828 20 15.886 20 14v-4c0-1.886 0-2.828-.586-3.414C18.828 6 17.886 6 16 6H8c-1.886 0-2.828 0-3.414.586zm10.664 5.847a.5.5 0 000-.866l-4.5-2.598a.5.5 0 00-.75.433v5.196a.5.5 0 00.75.433l4.5-2.598z"/></svg>`;
const PDF_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z" fill="#E81D1D"/><path d="M8.522 14c.301 0 .563.046.785.137.221.089.393.217.513.386.12.166.18.361.18.587 0 .226-.06.421-.18.587-.12.166-.292.294-.513.386a2.113 2.113 0 01-.785.133h-.58V17H7v-3h1.522zm-.057 1.547c.193 0 .339-.037.437-.111a.39.39 0 00.148-.326.39.39 0 00-.148-.326c-.098-.077-.244-.115-.437-.115h-.523v.878h.523zM10.46 14h1.574c.365 0 .688.061.97.184a1.5 1.5 0 01.657.523c.155.226.233.49.233.793 0 .303-.078.567-.233.793a1.5 1.5 0 01-.657.523 2.408 2.408 0 01-.97.184H10.46v-3zm1.536 2.323c.285 0 .514-.073.685-.219.174-.145.262-.347.262-.604s-.088-.459-.262-.604c-.171-.146-.4-.219-.685-.219h-.594v1.646h.594zM15.33 14.656v.66h1.47v.655h-1.47V17h-.94v-3H17v.656h-1.67z" fill="#fff"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z" fill="#E81D1D"/></svg>`;
const FILE_SVG = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9a267.31 267.31 0 01-2.166-.011C15.28 8.989 14 7.749 14 6.243V4H8a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V9z"/><path d="M16.62 7.994c.559.005 1.337.007 1.996.005.338 0 .51-.392.276-.628L15.66 4.115c-.24-.242-.66-.076-.66.26v2.05c0 .86.731 1.569 1.62 1.569z"/></svg>`;

export default {
  name: 'ChatMessageInput',
  mixins: [FveMixinForm],
  components: {
    // EmailDelaySending,
    FveTextareaResize,
    Dropzone,
    DropzoneDraggable,
  },
  data() {
    return {
      message: '',
      files: [],
    };
  },
  methods: {
    onUpdateItems(items) {
      for (let i = 0; i < items.length; i++) {
        const { filename, url, bucketname } = items[i];
        this.sendFile(filename, url, bucketname);
      }
    },

    formSchema() {
      return {
        message: {
          type: String,
          default() {
            return '';
          },
        },
      };
    },

    async selectFileList(files) {
      const fileList = Array.from(files);

      try {
        for (let i = 0; i < fileList.length; i++) {
          await this.upload(fileList[i]);
        }
      } catch (err) {}
    },

    onInput($event) {
      this.message = $event.target.innerHTML;
    },

    share(fileDataObj) {
      console.log('We in share method: ', fileDataObj);
      let shareObj = {
        skipAccessValidation: false,
        users: [],
        communities: [],
      };

      //
      const communityObj = communityGetSelected();
      if (communityObj && communityObj.value && communityObj.value.id) {
        shareObj.communities.push({
          type: 'R',
          id: communityObj.value.id,
        });
      }

      //
      const contactObj = contactGetSelected();
      if (contactObj && contactObj.value && contactObj.value.id) {
        shareObj.users.push({
          type: 'R',
          id: contactObj.value.id,
        });
      }

      RequestManager.FileStorage.shareFile({
        bucketName: fileDataObj.bucketname,
        filename: fileDataObj.filename.replace('/shared', ''),
        shareObj: shareObj,
      });
    },

    upload(file) {
      return RequestManager.FileStorage.add({ file: file, shared: true }).then(
        (res) => {
          this.share(res);
          this.sendFile(res.filename, res.url, res.bucketname);
        }
      );
    },

    type(filename) {
      const regExp = /[A-Za-z0-9]*$/gm;
      return filename.match(regExp)[0].toLowerCase();
    },

    sendFile(filename, url, bucketname) {
      const fileName = filename.replace('/shared', '');
      // const message = `<a href="${url}" target="_blank">${fileName}</a>\n`;
      const type = this.type(fileName);
      let icon = null;
      switch (type) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          icon = IMG_SVG;
          break;

        case 'doc':
        case 'docx':
          icon = DOC_SVG;
          break;

        case 'xls':
        case 'xlsx':
          icon = XLS_SVG;
          break;

        case 'mp4':
        case 'avi':
        case 'mov':
          icon = VIDEO_SVG;
          break;

        case 'pdf':
          icon = PDF_SVG;
          break;

        default:
          icon = FILE_SVG;
          break;
      }

      const message = `<div class="cm-tag" onclick="vueFix_chatFileClick(event, '${url}', '${fileName}', '${bucketname}');"><div class="cm-tag__icon">${icon}</div><div>${fileName}</div></div>`;
      this.$emit('sendMsg', message);
    },

    sendMsg() {
      if (this.message) {
        // const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)?/gm;
        const message = this.message.replaceAll(
          /(http[s]{0,1}\:\/\/\S{4,})\s{0}/gims,
          '<a href="$1" target="_blank">$1</a>'
        );
        this.$emit('sendMsg', message);
        this.message = '';
      }
    },
  },
  mounted() {
    global.vueFix_chatFileClick = function (event, url, filename, bucketname) {
      event.preventDefault();
      event.stopPropagation();

      if (url && filename) {
        fileStorageGetPreview({ filename: filename, bucketname: bucketname });
      }
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep() {
  .smooth-dnd-container {
    position: absolute;
    top: 0;
    left: 0;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: -1;
    &.smooth-dnd-shown {
      z-index: 999;
    }
  }
  .dropzone-overlay {
    border-radius: 0;
  }
  .dropzone-inner {
    display: flex;
    // padding: 20px 32px 20px 48px;
    background-color: var(--color-gray-01);
    border-top: 1px solid var(--color-gray-03);
    align-items: flex-end;
    width: -webkit-fill-available;
  }
}
.chat-input {
  // display: flex;
  // // padding: 20px 32px 20px 48px;
  // background-color: var(--color-gray-01);
  // border-top: 1px solid var(--color-gray-03);
  // align-items: flex-end;

  .chat-input__textarea {
    width: 100%;
    ::v-deep(textarea) {
      padding: 20px 40px;
      border: 0;
      resize: none;
      height: 64px;
      min-height: 24px;
    }
  }

  .chat-input__action {
    display: flex;
    margin-left: auto;
    margin-bottom: 16px;
    margin-right: 16px;
  }

  .chat-input__attach,
  .chat-input__send {
    width: 2rem;
    height: 2rem;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chat-input__attach {
    margin-right: 6px;
    cursor: pointer;
    & > svg {
      fill: var(--color-gray-04);
    }
  }

  .chat-input__send {
    cursor: pointer;
    background-color: var(--color-primary);
  }
}
</style>
